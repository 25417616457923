import { ErrorMessage } from "./error-message";
import { classNames } from "~/utils/formatters";
import { forwardRef } from "react";
import { useDisclosure } from "~/utils/hooks";
import { useField } from "remix-validated-form";

const ValidatedInput = forwardRef(
  (
    {
      customId,
      name,
      label,
      leadingIcon: LeadingIcon,
      showLabel = false,
      type = "text",
      validateValue = false,
      inputStyle,
      ...inputProps
    }: {
      customId?: string;
      name: string;
      label: string;
      type?: "email" | "text" | "password" | "number" | "tel" | "date" | "file";
      leadingIcon?: React.VFC<{ className?: string }>;
      validateValue?: boolean;
      showLabel?: boolean;
      inputStyle?: string;
    } & Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      "name" | "label" | "type" | "id" | "className" | "style"
    >,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const { isOpen: showPassword, toggle } = useDisclosure();
    const { error, getInputProps, validate } = useField(name, {
      validationBehavior: {
        initial: "onBlur",
        whenSubmitted: "onChange",
        whenTouched: "onChange",
      },
    });

    return (
      <div>
        {label.length ? (
          <label
            htmlFor={name}
            className={
              showLabel ? "mb-2 inline-block text-lg font-medium" : "sr-only"
            }
          >
            {label}
          </label>
        ) : null}
        <div className="group relative">
          {LeadingIcon ? (
            <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-2.5">
              <div className="transition-color grid place-items-center rounded-full bg-sky-400 p-1.5">
                <LeadingIcon className="h-5 w-5 stroke-white" />
              </div>
              <div className="absolute inset-y-0 left-0 grid place-items-center pl-1">
                <div className="h-11 w-11 bg-gradient-radial-sky opacity-0 mix-blend-color-dodge transition-opacity group-hover:opacity-100"></div>
              </div>
            </div>
          ) : null}
          <div className="relative">
            <input
              name={name}
              {...getInputProps({
                className: inputStyle
                  ? classNames(inputStyle)
                  : classNames(
                      "block w-full rounded-3xl border bg-transparent bg-clip-padding py-3 text-base text-white placeholder-white placeholder-opacity-60 transition",
                      LeadingIcon !== undefined ? "pl-14" : "pl-4",
                      type === "password" ? "pr-14" : "pr-4",
                      error
                        ? "border-red-400 border-opacity-100 hover:border-red-400 focus:border-red-400 focus:ring-red-400"
                        : "border-white border-opacity-30 hover:border-sky-400 focus:border-sky-400 focus:ring-sky-400",
                    ),
                id: customId ? customId : name,
                onBlur: () => {
                  if (validateValue) {
                    validate();
                  }
                },
                ref,
                style: {
                  backgroundImage:
                    "radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.3))",
                },
                type:
                  type === "password"
                    ? showPassword
                      ? "text"
                      : "password"
                    : type,
              })}
              {...inputProps}
            />
            {LeadingIcon ? (
              <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-2.5">
                <div className="transition-color grid place-items-center rounded-full bg-sky-400 p-1.5">
                  <LeadingIcon className="h-5 w-5 stroke-white" />
                </div>
                <div className="absolute inset-y-0 left-0 grid place-items-center pl-1">
                  <div className="h-11 w-11 bg-gradient-radial-sky opacity-0 mix-blend-color-dodge transition-opacity group-hover:opacity-100"></div>
                </div>
              </div>
            ) : null}
            {type === "password" ? (
              <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                <button
                  type="button"
                  onClick={toggle}
                  className="inline-flex shrink-0 items-center rounded-2xl border border-transparent bg-white bg-opacity-0 p-1.5 text-sm text-white transition-colors hover:bg-opacity-5"
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <ErrorMessage {...{ error }} />
      </div>
    );
  },
);
if (process.env.NODE_ENV === "development") {
  ValidatedInput.displayName = "Input";
}

export { ValidatedInput };
